<template>
  <div class="login-page">
    <v-card>
      <v-form class="login-page__form pa-12 text-left" @submit="signIn">
        <div class="login-page__logo-container">
          <Logo/>
        </div>
        <v-text-field class="login-page__input-email" label="Email" type="email" v-model="credentials.email" append-icon="mdi-account" />
        <v-text-field class="login-page__input-password" label="Password" :type="pwdType" v-model="credentials.password" :append-icon="pwdIcon" @click:append="togglePwdMode" />
        <h6 class="login-page__error">{{ error }}</h6>
        <h6 class="login-page__success">{{ message }}</h6>

        <div class="text-center ma-6">
          <v-btn
            outlined
            color="primary"
            type="submit"
          >
            Login
          </v-btn>
        </div>

        <v-divider />

        <div class="mt-4">
          <router-link to="/forgot-password">
            <p class="login-page__recovery-link">Hai dimenticato la password?</p>
          </router-link>
        </div>
      </v-form>
    </v-card>
    <Loading v-if="isLoading"/>
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import { authService } from "@/services/authService";
import { localStorageService } from "@/services/localStorageService.js";
import Logo from "@/components/logo";
import Loading from "@/components/loading";

@Component({
  components:{
    Logo,
    Loading
  }
})

export default class Login extends Vue {
  showPwdMode = false;
  error = "";
  message = ""
  isLoading = false;
  credentials = {
    email: "",
    password: ""
  };

  get pwdType () {
    return this.showPwdMode ? "text" : "password";
  }

  get pwdIcon () {
    return this.showPwdMode ? "mdi-eye-off" : "mdi-eye";
  }

  togglePwdMode () {
    this.showPwdMode = !this.showPwdMode;
  }

  async signIn ($event) {
    $event.preventDefault();
    if (!this.credentials.email || !this.credentials.password) {
      this.error = "Email and password are both required";
      return;
    }
    this.error = "";
    this.isLoading = true;
    try {
      const user = await authService.signIn({
        email: this.credentials.email,
        password: this.credentials.password
      });

      user && localStorageService.setLoggedUser(user);
      this.$store.commit("SET_CURRENT_USER", user);

      this.$router.push("/");
      this.isLoading = false;

    } catch (error) {
        this.isLoading = false;
        this.handleErrorSuccessMessage(error.description, "fail");
    }

	}

  handleErrorSuccessMessage(msg, status){
    if(status === "success"){
      this.message = msg;

    setTimeout(()=>{
      this.message = "";
      }, 4000);
    }
    if(status === "fail"){
      this.error = msg;

    setTimeout(()=>{
      this.error = "";
      }, 4000);
    }

  }
}
</script>

<style lang="scss" scoped>
.login-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EBEEF7;
  background: url("/bg_sky.jpg"), linear-gradient(145deg, #e6e6e6, #ffffff), #EBEEF7;

  &__logo-container{
    width: 320px;
    margin: 0 auto 25px;
  }

  &__error {
    color: #D63B01;
  }

  &__recovery-link{
    color: #4C4C4C;
    font-size: 13px;
  }

  &__success {
    color: #8BD499;
  }
}
</style>